<template>
    <div class="pagamento">
        <div class="container">
            <div class="col-12 col-xl-10 offset-xl-1">
                <div class="row">
                    <div class="col-12 mt-5 text-center titulo">
                        <h1>Pagamento</h1>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-10 offset-xl-1 mt-5">
                <div class="row">
                    <div class="col-12 mb-4">
                        <h2>Segue os dados para o pagamento:</h2>
                        <h2 class="mt-3">
                            <strong
                                >Pedido: {{ pagamento.pedido.codigo }}</strong
                            >
                        </h2>
                    </div>
                    <div
                        class="
                            col-12 col-md-7
                            d-flex
                            mt-4
                            flex-column flex-md-row
                        "
                    >
                        <img
                            width="248" height="248"
                            class="pe-4 pb-4"
                            :src="pagamento.pedido.qrcode"
                            alt=""
                        />
                        <div>
                            <h3>Pague o valor via Pix</h3>
                            <h4>
                                {{
                                    pagamento.pedido.valorTotal
                                        | formatarMoedaBR
                                }}
                            </h4>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 mt-4">
                        <h3>Como Pagar?</h3>
                        <h5>
                            Utilize o seu aplicativo bancário para a leitura
                            desse QRCODE.
                        </h5>
                        <h5>Caso tenha problemas poderá usar a chave PIX:</h5>
                        <h2 class="text-center mt-4">
                            <strong>(11) 98195-4277</strong>
                        </h2>
                        <h5 class="mt-4">
                            No comentário da mensagem do PIX, afim de facilitar
                            mencione o código do seu pedido.
                        </h5>
                    </div>
                    <div class="col-12 5 mt-5" v-if="pagamento.statusEmail">
                        <h2 class="mt-3">
                            Uma cópia deste pedido foi enviado para seu e-mail.
                        </h2>
                    </div>
                    <div class="col-12">
                        <router-link
                            class="btn btn-site mx-auto d-table mt-5"
                            :to="{ name: 'MeusPedidos' }"
                        >
                            Meus Pedidos
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "Pagamento",

    data() {
        return {
            pagamento: {
                pedido: {
                    id: "",
                    codigo: "",
                    qrcode: "",
                    valorTotal: 0,
                },
            },
        };
    },

    methods: {
        ...mapActions(["finalizarPedido", "limparCarrinho", "limparDados"]),
    },

    async mounted() {
        this.pagamento = this.$route.params;
        this.finalizarPedido(false);
        await this.limparCarrinho();
        await this.limparDados();
        window.gtag('event', 'conversion', {'send_to': 'AW-1040996035/x9NTCK2Q8bIDEMOtsfAD'});
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
.pagamento {
    @include background;

    .titulo {
        @include titulo;
    }
    .btn-site {
        @include btn-site;
    }
    h2 {
        color: $marrom;
        font-family: $roboto;
        font-weight: normal;
        font-style: normal;
        font-size: 22px;
        line-height: 22px;
    }
    h3 {
        color: $marrom;
        font-family: $roboto;
        font-weight: bold;
        font-style: normal;
        font-size: 22px;
        line-height: 22px;
    }
    h4 {
        color: $marrom;
        font-family: $roboto;
        font-weight: bold;
        font-style: normal;
        font-size: 29px;
        line-height: 29px;
    }
    h5 {
        color: $marrom;
        font-family: $roboto;
        font-weight: normal;
        font-style: normal;
        font-size: 18px;
        line-height: 22px;
    }
}
@media (max-width: 767.98px) {
    .pagamento {
        padding: 50px 0 80px;
    }
}
</style>
